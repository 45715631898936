import React, { useState } from 'react'
import SdfContext from './sdfContext'
import { formatDate } from 'src/components/common/utils/date_Utils'
const Sdf_Provider = ({ children }) => {
  const todayDate = new Date()
  const minDate = new Date()
  minDate.setMonth(minDate.getMonth() - 2)
  const [cvCalender, setCvCalender] = useState({
    minDate: minDate,
    maxDate: todayDate,
    selectedWeek: null,
    selectedDate: todayDate,
    weekRangeArray: [],
    weekRange: null,
    displayText: formatDate(todayDate),
  })
  return <SdfContext.Provider value={{ cvCalender, setCvCalender }}>{children}</SdfContext.Provider>
}
export default Sdf_Provider
