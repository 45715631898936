import React, { useState } from 'react'
import StockContext from './StockContext'

const StockProvider = ({ children }) => {
  const [stockStore, setStockStore] = useState(false)
  const [stockOnline, setStockOnline] = useState(true)

  return (
    <StockContext.Provider value={{ stockStore, setStockStore, stockOnline, setStockOnline }}>
      {children}
    </StockContext.Provider>
  )
}
export default StockProvider
