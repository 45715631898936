import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import PPContext from './PPContext'
const PP_Provider = ({ children }) => {
  PP_Provider.propTypes = {
    children: PropTypes.node,
  }
  const [componentName, setComponentName] = useState(undefined)
  const [consolidateVisibleModal, setConsolidateVisibleModal] = useState(false)
  const [priceOnline, setPriceOnline] = useState(true)
  const [priceStore, setPriceStore] = useState(false)
  const [store, setStore] = useState(true)
  const [online, setOnline] = useState(false)
  const [mapFlow, setMapFlow] = useState({})
  const [mapData, setMapData] = useState([])
  const [popShow, setPopShow] = useState(false)
  const [changeDate, setChangeDate] = useState(false)
  const [showSystemModal, setShowSystemModal] = useState(false)
  const [visible, setVisible] = useState(false)
  const [storeModal, setStoreModal] = useState(false)
  const [active, setActive] = useState(true)
  const [sysName, setSysName] = useState('SAP ECC')
  const [systemName, setSystemName] = useState('sap ecc')
  const [summaryData, setSummaryData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [pos, setPos] = useState(false)
  const value = useMemo(
    () => ({
      priceOnline,
      setPriceOnline,
      priceStore,
      setPriceStore,
      mapFlow,
      setMapFlow,
      store,
      setStore,
      online,
      setOnline,
      popShow,
      setPopShow,
      mapData,
      setMapData,
      changeDate,
      setChangeDate,
      showSystemModal,
      setShowSystemModal,
      visible,
      setVisible,
      storeModal,
      setStoreModal,
      active,
      setActive,
      sysName,
      setSysName,
      summaryData,
      setSummaryData,
      systemName,
      setSystemName,
      isLoaded,
      setIsLoaded,
      pos,
      setPos,
      componentName,
      setComponentName,
      consolidateVisibleModal,
      setConsolidateVisibleModal,
    }),
    [
      storeModal,
      active,
      summaryData,
      sysName,
      systemName,
      isLoaded,
      visible,
      showSystemModal,
      changeDate,
      mapData,
      priceOnline,
      priceStore,
      mapFlow,
      store,
      online,
      popShow,
      pos,
      componentName,
      consolidateVisibleModal,
    ],
  )
  return <PPContext.Provider value={value}>{children}</PPContext.Provider>
}
export default PP_Provider
