const monthNameArr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const formatDate = (date) => {
  const options = { month: 'long', year: 'numeric' }
  const formattedDate = new Date(date).toLocaleDateString('en-GB', options)
  const day = new Date(date).getDate()
  let suffix = 'th'

  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st'
  } else if (day === 2 || day === 22) {
    suffix = 'nd'
  } else if (day === 3 || day === 23) {
    suffix = 'rd'
  }

  return `${day}${suffix} ${formattedDate}`
}
const formatShortWeekday = (_, date) => {
  const day = date.toLocaleString('en-US', { weekday: 'short' })
  return day.substr(0, 2)
}

const getDateDetails = (selectedDay) => {
  const date = new Date()
  if (selectedDay === 'prevmonth') {
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    let lastDay = new Date(date.getFullYear(), date.getMonth(), 0)
    return {
      FromDate: formatDateStandard(firstDay),
      ToDate: formatDateStandard(lastDay),
    }
  } else {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    let lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return {
      FromDate: formatDateStandard(firstDay),
      ToDate: formatDateStandard(lastDay),
    }
  }
}
const formatDateStandard = (d) => {
  return (
    d?.getFullYear() +
    '-' +
    (d?.getMonth() + 1 > 10 ? d?.getMonth() + 1 : '0' + (d?.getMonth() + 1)) +
    '-' +
    d?.getDate()
  )
}
const getPlainDate = (d) => {
  return d?.getMonth() + 1 + '/' + d?.getDate() + '/' + d?.getFullYear()
}
const getWeekNumber = (d) => {
  const startDate = new Date(d?.getFullYear(), 0, 1)
  const days = Math.floor((d - startDate) / (24 * 60 * 60 * 1000))
  return Math.ceil(days / 7)
}
const getFirstAndLastDateM = (t) => {
  // Parse the input text to extract month and year
  const [month, year] = t.split(' ')

  // Create a date object for the first day of the specified month and year
  const firstDayOfMonth = new Date(`${month} 1, ${year}`)

  // Calculate the last day of the month
  const lastDayOfMonth = new Date(firstDayOfMonth)
  lastDayOfMonth.setMonth(firstDayOfMonth.getMonth() + 1)
  lastDayOfMonth.setDate(0) // This sets the day to the last day of the previous month
  let toDate
  // Check if the last date exceeds today's date
  const today = new Date()
  if (lastDayOfMonth > today) {
    toDate = getPlainDate(today)
  } else {
    toDate = getPlainDate(lastDayOfMonth)
  }
  return {
    FromDate: getPlainDate(firstDayOfMonth),
    ToDate: toDate,
  }
}
const getFirstAndLastDateW = (date) => {
  let today = new Date()
  let tempDay = new Date(date)
  let startD = getPlainDate(tempDay)
  let endD = tempDay

  endD?.setDate(tempDay?.getDate() + 6)
  if (endD > today) {
    endD = getPlainDate(today)
  } else {
    endD = getPlainDate(endD)
  }
  return {
    FromDate: startD, //month/date/year
    ToDate: endD,
  }
}

const getDatesBetween = (dateR) => {
  const fromDate = new Date(dateR?.FromDate)
  let toDate = new Date(dateR?.ToDate)
  const dates = []
  let currentDate = fromDate
  let i = 0
  while (currentDate <= toDate) {
    if (i > 7) {
      currentDate = 1
      toDate = 0
    }
    dates.push(currentDate.toLocaleDateString())
    currentDate.setDate(currentDate.getDate() + 1)
    i += 1
  }

  return dates
}
export {
  formatDate,
  formatShortWeekday,
  getDateDetails,
  formatDateStandard,
  getPlainDate,
  getWeekNumber,
  getFirstAndLastDateM,
  getFirstAndLastDateW,
  monthNameArr,
  getDatesBetween,
}
