import 'react-app-polyfill/stable'
import 'core-js'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig } from './config/MsalConfig'
import PPProvider from './Contexts/Price-Promotions/PP_Provider'
import SdfProvider from './Contexts/SDF/sdf_Provider'
import StockProvider from './Contexts/Stock/StockProvider'
export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const rootElement = document.getElementById('root') || document.createElement('div')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <Router>
      <PPProvider>
        <SdfProvider>
          <StockProvider>
            <App pca={msalInstance} />
          </StockProvider>
        </SdfProvider>
      </PPProvider>
    </Router>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
