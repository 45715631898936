let url = window.location.origin
export const environmentData = {
  run:
    url === 'https://dataflow.hmgroup.com' ||
    url === 'https://zealous-tree-0c16ad603.3.azurestaticapps.net'
      ? 'production'
      : 'development',

  development: {
    clientId: 'a8931c18-751f-4fc6-86e6-6975503641b2',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid a8931c18-751f-4fc6-86e6-6975503641b2/User.Impersonation',
    // logs in azure
    appInsightConnectionString:
      'InstrumentationKey=b94e5c9c-651a-4638-a81c-c4a389fc8a89;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/',
    //api
    apiBaseURL: 'https://dev-api-sdf-dashboard.azurewebsites.net/api',
    stockApiBaseURL: 'https://dev-api-stockdataflow-dashboard.azurewebsites.net/api',
    ppApiBaseURL: 'https://dev-api-priceandpromotion-dashboard.azurewebsites.net/',
    hoursApiBaseURL: 'https://dev-api-hdf-dashboard.azurewebsites.net/',
  },
  staging: {
    clientId: 'a8931c18-751f-4fc6-86e6-6975503641b2',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid a8931c18-751f-4fc6-86e6-6975503641b2/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=b94e5c9c-651a-4638-a81c-c4a389fc8a89;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/',

    apiBaseURL: 'https://dev-api-sdf-dashboard.azurewebsites.net/api',
    stockApiBaseURL: 'https://dev-api-stockdataflow-dashboard.azurewebsites.net/api',
    ppApiBaseURL: 'https://dev-api-priceandpromotion-dashboard.azurewebsites.net/',
    hoursApiBaseURL: 'https://dev-api-hdf-dashboard.azurewebsites.net/',
  },
  production: {
    clientId: 'a8931c18-751f-4fc6-86e6-6975503641b2',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid a8931c18-751f-4fc6-86e6-6975503641b2/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=b9945a98-c86c-4feb-b9c8-5078dd36d3d4;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/',
    apiBaseURL: 'https://prod-api-sdf-dashboard.azurewebsites.net/api',
    stockApiBaseURL: 'https://prod-api-stockdataflow-dashboard.azurewebsites.net/api',
    ppApiBaseURL: 'https://dev-api-priceandpromotion-dashboard.azurewebsites.net/',
    hoursApiBaseURL: 'https://prod-api-hdf-dashboard.azurewebsites.net/',
  },
}
